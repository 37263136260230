<template>
  <div class="table d-flex f-wrap">
    <!-- <div id="geoTimeLineContainer"></div> -->
  </div>
</template>

<script>
import { GeoTimeLine, GeoTimeScale } from '@zjugis/geo-timeline';

export default {
  data () {
    return {
      hope1: null,
      hope: null,
    };
  },
  created () {
    const intervals = require('./are.json');
    // this.hope = new GeoTimeLine('#geoTimeLineContainer', intervals);
    // this.hope1 = new GeoTimeScale('#geoTimeLineContainer', intervals);
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 95%;
  margin: 0 auto;
  margin-bottom: 20px;
  height: 100px;
  text-align: center;
  line-height: 50px;
  font-size: 12px;

  &-paleozoic {
    width: 45%;
    height: 100%;
    background-color: #99c08d;

    &-name {
      width: 100%;
      height: 50%;
      border: 1px solid #fff;
      box-sizing: border-box;
    }

    &-item {
      height: 50%;
      border: 1px solid #fff;
      box-sizing: border-box;
    }
  }

  &-mesozoic {
    width: 30%;
    height: 100%;
    background-color: #67c5ca;

    &-name {
      width: 100%;
      height: 50%;
      border: 1px solid #fff;
      box-sizing: border-box;
    }

    &-item {
      height: 50%;
      border: 1px solid #fff;
      box-sizing: border-box;
    }
  }

  &-cenozoic {
    width: 25%;
    height: 100%;
    background-color: #f2f91d;

    &-name {
      width: 100%;
      height: 50%;
      border: 1px solid #fff;
      box-sizing: border-box;
    }

    &-item {
      height: 50%;
      border: 1px solid #fff;
      box-sizing: border-box;
    }
  }
}

// 作为一些常用样式
.d-flex {
  display: flex;
}

.a-center {
  align-items: center;
}

.f-wrap {
  flex-wrap: wrap;
}

.white {
  color: #ffffff;
}
</style>
